import React from 'react';
import './Checkbox.css';
//import '@tobiipro/lunar';

const Checkbox = (props) => (
  <label className="container-cb">
    <span className="checkbox-label">{props.label}</span>
    <input {...props} type="checkbox" />
    <span className="checkmark"></span>
  </label>
);

export default Checkbox;
