import BaseApiService from './BaseApiService';

const ENDPOINTS = {
  GET: '/project',
};

class ProjectService extends BaseApiService {
  async get() {
    return await this.apiClient.get(ENDPOINTS.GET);
  }
}

export default new ProjectService();
