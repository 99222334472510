import dotenv from 'dotenv';

const activeEnv =
  process.env.REACT_ACTIVE_ENV || process.env.NODE_ENV || 'development';

dotenv.config({
  path: `.env.${activeEnv}` // or '.env'
});

const config = {
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
};

export default config;
