import { LOCALES } from '../constants';

export default {
  [LOCALES.ENGLISH]: {
    'edit-file': 'Edit {file} and save to reload.',
    hello: 'Hello',
    'demo-placeholder': 'Placeholder sample',
    'new-report': 'new report',
    'view-reports': 'view reports',
    'time-report': 'Tobii Project Time Report',
    'welcome-text': 'Add project time',
    'select-discipline-placeholder': 'Select discipline, type to search',
    'select-project-placeholder': 'Select project, type to search',
    'allocation-placeholder': 'Hours',
    'comment-placeholder': 'Add comment',
    'thead-discipline': 'Discipline',
    'thead-project': 'Project',
    'thead-hours': 'Hours',
    'thead-comment': 'Comment',
    'logout-button': 'Log Out',
    'help-button': 'Help',
    'mark-as-done': 'Mark time report as done for this month',
    'add-button': 'ADD',
    'remaining-hours':
      'Out of {maxHours} hours this month, {remaining} remaining',
    'remaining-hours-short': 'Out of {maxHours} hours, {remaining} remaining',
    'import-previous': 'Import previous month projects',
    'delete-row': 'Delete row',
    'delete-text':
      'Are you sure you want to delete this row? Reported hours for this project will be removed from your total.',
    'confirm-delete': 'Delete',
    cancel: 'Cancel',
    menu: 'menu',
    'add-project-time': 'Add project time',
    'save-button': 'save',
    'delete-button': 'delete',
    'edit-text': 'Edit time report',
    'no-history': 'No past time reports.',
    'confirm-button': 'Confirm',
    'mark-warning-first-paragraph':
      'You were editing a new project time row, if you continue, the row will not be added and the timereport will be marked as done.',
    'mark-warning-second-paragraph':
      'If you want to add the row, please cancel and then add the row before marking as done.',
    'auth-error-first-paragraph':
      'We’re sorry, there was a problem with your authentication.',
    'auth-error-second-paragraph':
      'Please contact {link} for further assistance.',
    'auth-error-header': 'We couldn’t authenticate you',
  },
};
