import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import MainPage from './containers/MainPage/MainPage';
import * as serviceWorker from './serviceWorker';
import { I18nPropvider } from './i18n';
import { UserProvider } from './context/userContext';
import AuthService from './services/AuthService';
import Loader from './components/Loader';
import HttpError from './containers/HttpErrorPage';
import { Routes } from './containers/routes';

const App = () => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(false);

  const fetchUser = async () => {
    if (window.location.href.indexOf(Routes.HTTP_ERROR) !== -1) {
      setError(true);
      return;
    }
    const response = await AuthService.me();
    setUser(response.data);
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <I18nPropvider>
      <UserProvider value={user}>
        {user ? (
          <MainPage />
        ) : !error ? (
          <div className="loader">
            <Loader /> Connecting...
          </div>
        ) : (
          <HttpError />
        )}
      </UserProvider>
    </I18nPropvider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
