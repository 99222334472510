import React from 'react';
import './MainPage.css';
import ViewReportsPage from '../ViewReportsPage';
import NewReportPage from '../NewReportPage';
import Header from '../../components/Header';
import Tab from '../../components/TabView/Tab';
import TabList from '../../components/TabView/TabList';
import { FormattedMessage } from 'react-intl';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Routes } from '../routes';
import AddReportPageMobile from '../AddReportPageMobile';
import EditReportPageMobile from '../EditReportPageMobile';
import HttpError from '../HttpErrorPage';

const MainPage = () => (
  <div className="wrapper">
    <BrowserRouter>
      <Header />
      <TabList>
        <Tab
          path={Routes.NEW_REPORT}
          name={<FormattedMessage id="new-report" />}
        />
        <Tab
          path={Routes.VIEW_REPORTS}
          name={<FormattedMessage id="view-reports" />}
        />
      </TabList>
      <Switch>
        <Route exact path={Routes.NEW_REPORT} component={NewReportPage} />
        <Route exact path={Routes.VIEW_REPORTS} component={ViewReportsPage} />
        <Route
          exact
          path={Routes.ADD_REPORT_MOBILE}
          component={AddReportPageMobile}
        />
        <Route
          exact
          path={Routes.EDIT_REPORT_MOBILE}
          component={EditReportPageMobile}
        />
      </Switch>
    </BrowserRouter>
  </div>
);

export default MainPage;
