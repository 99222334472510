import React, { useContext, useEffect, useState } from 'react';

import { FormattedMessage } from 'react-intl';

import TimeReportService from '../../services/TimeReportService';
import FinishedMonthService from '../../services/FinishedMonthService';

import Table from '../../components/Table';
import Summary from '../../components/Summary';
import Checkbox from '../../components/Checkbox';

import './NewReport.css';
import { DisplayDate } from '../../utils/DateUtil';
import AddForm from '../../components/TimeReport/Form/addForm';
import Button from '../../components/Button';
import { Link, useHistory } from 'react-router-dom';
import { Routes } from '../routes';
import UserContext from '../../context/userContext';
import useIsMobile, { useDimensions } from '../../hooks/useIsMobile';
import WarningModal from '../../components/WarningModal';

const marginOffset = 300;

const NewReportPage = () => {
  const user = useContext(UserContext);

  const [timeReports, setTimeReports] = useState([]);
  const [workhours, setWorkhours] = useState(0);
  const [editable, setEditable] = useState(false);
  const [modalId, setModalId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [markWarning, setMarkWarning] = useState(false);
  const [tableHeight, setTableHeight] = useState(100);

  const isMobile = useIsMobile();
  const [_, height] = useDimensions();
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    fetchTimeReports();
  }, []);

  useEffect(() => {
    if (isMobile && !loading && timeReports && timeReports.length === 0) {
      history.push(Routes.ADD_REPORT_MOBILE);
    }
  }, [isMobile]);

  useEffect(() => {
    if (isMobile) {
      setTableHeight(height - marginOffset);
    }
  }, [isMobile, height]);

  const handleEditTimeReport = async (id, doc) => {
    const updatedTimeReports = timeReports.map((item) => {
      return id === item.id
        ? {
            ...item,
            allocation: doc.allocation || item.allocation,
            comment: doc.comment || item.comment,
          }
        : item;
    });
    setTimeReports(updatedTimeReports);
    await TimeReportService.update(id, doc);
  };

  const handleDeleteTimeReport = async (id) => {
    await TimeReportService.delete(id);
    const updatedTimeReports = timeReports.filter((item) => item.id !== id);

    setTimeReports(updatedTimeReports);
  };

  const fetchTimeReports = async () => {
    const { data } = await TimeReportService.get(user.officeLocation);
    if (isMobile && data.timereports.length === 0) {
      history.push(Routes.ADD_REPORT_MOBILE);
      return;
    }
    setTimeReports(data.timereports);
    setWorkhours(data.workhours);
    setEditable(!data.finished);
    setLoading(false);
  };

  const handleSubmit = async (values) => {
    const { data } = await TimeReportService.create(values);
    setTimeReports([...timeReports, data]);
  };

  const handleMarkAsDone = async (event) => {
    const checked = event.target.checked;
    if (sessionStorage.length > 0 && checked && !isMobile) {
      setMarkWarning(true);
      return;
    }

    FinishedMonthService.setMarked(checked);
    setEditable(!checked);
  };

  const handleImport = async () => {
    const { data } = await TimeReportService.importPrevious();
    setTimeReports([...timeReports, ...data]);
  };

  return (
    <div className="home_wrapper">
      <div className="container">
        <WarningModal
          isOpen={!!modalId}
          header={<FormattedMessage id="delete-row" />}
          body={<FormattedMessage id="delete-text" />}
          onConfirm={() => {
            handleDeleteTimeReport(modalId);
            setModalId(null);
          }}
          onCancel={() => setModalId(null)}
          confirmText={<FormattedMessage id="confirm-delete" />}
        />
        <WarningModal
          isOpen={markWarning}
          header={<FormattedMessage id="mark-as-done" />}
          body={
            <div>
              <p>
                <FormattedMessage id="mark-warning-first-paragraph" />
              </p>
              <br></br>
              <p>
                <FormattedMessage id="mark-warning-second-paragraph" />
              </p>
            </div>
          }
          onConfirm={() => {
            setMarkWarning(false);
            FinishedMonthService.setMarked(true);
            setEditable(false);
          }}
          onCancel={() => setMarkWarning(false)}
          confirmText={<FormattedMessage id="confirm-button" />}
        />
        <div className="content-container">
          <div className="welcome-text">
            <FormattedMessage id="welcome-text" />
          </div>
          <AddForm handleSubmit={handleSubmit} editable={editable} />
          <div className="import-button">
            <p onClick={() => editable && handleImport()}>
              <FormattedMessage id="import-previous" />
            </p>
          </div>
          <div className="date-text">
            <DisplayDate date={timeReports.length > 0 && new Date()} />
          </div>
          <Table
            onTimeReportUpdate={handleEditTimeReport}
            onTimeReportDelete={(id) => setModalId(id)}
            data={timeReports}
            editable={editable}
            loading={loading}
            disabledStyle
            style={isMobile && { container: { maxHeight: tableHeight } }}
            summaryMessage="remaining-hours"
          />
          {!loading && (
            <>
              <Summary
                data={timeReports}
                maxHours={workhours}
                summaryMessage="remaining-hours"
              />
              <div className="mark-as-done">
                <Checkbox
                  label={<FormattedMessage id="mark-as-done" />}
                  className="input-checkbox"
                  checked={!editable}
                  type="checkbox"
                  onChange={handleMarkAsDone}
                />
              </div>
            </>
          )}
        </div>
        <div className="footer-container">
          <Link
            to={{
              pathname: Routes.ADD_REPORT_MOBILE,
              state: { timeReports, editable },
            }}
          >
            <Button disabled={!editable} className="add-project-mobile-button">
              <FormattedMessage id="add-project-time" />
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NewReportPage;
