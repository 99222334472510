import React, { Fragment } from 'react';
import { IntlProvider } from 'react-intl';

import { LOCALES } from './constants';
import messages from './messages';

const Provider = ({ children, locale = LOCALES.ENGLISH }) => (
  <IntlProvider
    textComponent={Fragment}
    locale={locale}
    messages={messages[locale]}
  >
    {children}
  </IntlProvider>
);

Provider.displayName = 'I18nProvider';

export default Provider;
