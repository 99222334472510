import * as Yup from 'yup';

const objectFields = { value: Yup.string(), label: Yup.string() };

export const addReportSchema = Yup.object({
  project: Yup.object(objectFields).required('Please select a valid project.'),
  discipline: Yup.object(objectFields).required(
    'Please select a valid discipline.',
  ),
  allocation: Yup.number('Only number are allowed.')
    .required('Please allocate hours.')
    .notOneOf([0], 'Hours must not be 0.'),
  comment: Yup.string(),
});

export const editReportSchema = Yup.object({
  allocation: Yup.number('Only number are allowed.')
    .required('Please allocate hours.')
    .notOneOf([0], 'Hours must not be 0.'),
});
