import React, { useState } from 'react';
import './Table.css';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as TrashIcon } from './Trash.svg';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../containers/routes';
import { ThreeDots } from 'svg-loaders-react';
import { ReactComponent as ArrowIcon } from './Arrow.svg';

const Table = (props) => (
  <div className="table-container" style={props.style?.container}>
    <table
      className={clsx(!props.editable && props.disabledStyle && 'disabled')}
    >
      {props.showHeader && (
        <thead>
          <tr>
            <th className="project">
              <FormattedMessage id="thead-project" />
            </th>
            <th className="discipline">
              <FormattedMessage id="thead-discipline" />
            </th>
            <th className="hours">
              <FormattedMessage id="thead-hours" />
            </th>
            <th className="comment">
              <FormattedMessage id="thead-comment" />
            </th>
            <th className="options"></th>
          </tr>
        </thead>
      )}
      {props.data && props.data.length > 0 ? (
        <tbody>
          {props.data.map((item) => (
            <TableRow key={item.id} item={item} {...props} />
          ))}
        </tbody>
      ) : !props.loading ? (
        !props.hideEmptyState && <tr className="separator empty-row"></tr>
      ) : (
        <div className="table-loader">
          <ThreeDots width="60" fill="#273859" />
        </div>
      )}
    </table>
  </div>
);

const TableRow = ({
  item,
  editable,
  onTimeReportDelete,
  onTimeReportUpdate,
}) => {
  const [editing, setEditing] = useState(false);
  const [editingComment, setEditingComment] = useState(false);
  const [hovered, setHovered] = useState(false);

  const handleKeyDown = (event, timereport, key) => {
    switch (event.key) {
      case 'Enter':
        finishEdit(timereport.id, key, timereport[key], event.target.value);
        break;
      case 'Escape':
        setEditing(false);
        setEditingComment(false);
        break;
      default:
        break;
    }
  };

  const finishEdit = (id, key, oldValue, newValue) => {
    setEditing(false);
    setEditingComment(false);
    const doc = {}
    doc[key] = newValue;
    onTimeReportUpdate(id, doc);
  };

  const handleBlur = (event, timereport, key) => {
    finishEdit(timereport.id, key, timereport[key], event.target.value);
  };

  const handleDelete = (id) => {
    onTimeReportDelete(id);
  };

  return (
    <>
      <tr
        className={clsx('table-desktop-row', editable && 'table-hover')}
        onMouseOver={() => editable && setHovered(true)}
        onMouseLeave={() => editable && setHovered(false)}
      >
        <td className="project">
          <p className="column-content first">
            {item.project.id} - {item.project.name}
          </p>
        </td>
        <td className="discipline">
          <p className="column-content">
            {item.discipline.id} - {item.discipline.name}
          </p>
        </td>
        <td
          className="hours"
          name="allocation"
          onClick={() => editable && setEditing(true)}
        >
          {editable && editing ? (
            <input
              className="allocation-input"
              defaultValue={item.allocation}
              onKeyDownCapture={(event) => handleKeyDown(event, item, 'allocation')}
              onBlur={(event) => handleBlur(event, item, 'allocation')}
              type="number"
              autoFocus
            />
          ) : (
            <p
              className={clsx(
                'column-content',
                editable && hovered && 'framed',
              )}
            >
              {item.allocation}
            </p>
          )}
        </td>
        <td className="comment" name="comment" onClick={() => editable && setEditingComment(true)}>
          {editable && editingComment ? (
            <input
              className="allocation-input"
              defaultValue={item.comment}
              onKeyDownCapture={(event) => handleKeyDown(event, item, 'comment')}
              onBlur={(event) => handleBlur(event, item, 'comment')}
              type="text"
            />
          ) : (
            <p
              className={clsx(
                'column-content',
                editable && hovered && 'framed',
              )}
            >
              {item.comment}
            </p>
          )}
        </td>
        <td className="options">
          {editable && (
            <div
              className="table-delete"
              style={{ opacity: hovered ? 0.8 : 0 }}
              onClick={() => handleDelete(item.id)}
            >
              <TrashIcon />
            </div>
          )}
        </td>
      </tr>
      <MobileOnlyRow item={item} editable={editable} />
    </>
  );
};

const MobileOnlyRow = ({ item, editable }) => {
  const history = useHistory();

  const handleClick = () => {
    editable &&
      history.push(Routes.EDIT_REPORT_MOBILE, {
        id: item.id,
        project: item.project,
        discipline: item.discipline,
        comment: item.comment,
        allocation: item.allocation,
      });
  };

  return (
    <tr className="table-mobile-row" onClick={handleClick}>
      <div className="mobile-row-data">
        <td className="project">
          <p className="column-content">
            <span className="table-mobile-label">Proj:</span>
            <span>
              {item.project.id} - {item.project.name}
            </span>
          </p>
        </td>
        <td className="discipline">
          <p className="column-content">
            <span className="table-mobile-label">Disc:</span>
            <span>
              {item.discipline.id} - {item.discipline.name}
            </span>
          </p>
        </td>
        <td className="hours" name="allocation">
          <p className="column-content">
            <span className="table-mobile-label">Hrs:</span>
            <span>{item.allocation}</span>
          </p>
        </td>
        <td className="comment">
          <p className="column-content">
            <span className="table-mobile-label">Cmt:</span>
            <span>{item.comment}</span>
          </p>
        </td>
      </div>
      {editable && (
        <div className="mobile-table-arrow">
          <ArrowIcon />
        </div>
      )}
    </tr>
  );
};

Table.propTypes = {
  data: PropTypes.array,
  onTimeReportUpdate: PropTypes.func,
  onTimeReportDelete: PropTypes.func,
  editable: PropTypes.bool,
  showHeader: PropTypes.bool,
};

export default Table;
