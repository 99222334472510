import React, { useState } from 'react';
import Highlighter from 'react-highlight-words';
import { ReactComponent as SelectedIcon } from './SelectedIcon.svg';
import Select, { components } from 'react-select';

const SearchSelect = (props) => {
  const [query, setQuery] = useState('');
  const searchWords = query.split(/\s/).filter((word) => word);

  return (
    <div className={props.className}>
      <label className="input-label">{props.label}</label>
      <Select
        styles={{
          container: (provided) => ({
            ...provided,
            maxHeight: 32,
          }),
          control: (provided, { isFocused }) => ({
            ...provided,
            background: isFocused
              ? 'white'
              : props.error
              ? 'var(--comet-red)'
              : 'var(--starlight-blue)',
            borderRadius: '3px',
            minHeight: '2rem',
            maxHeight: '2rem',
            border: props.error ? '1px solid var(--red)' : 'none',
            height: 'auto',
          }),
          placeholder: (provided, { isDisabled }) => ({
            ...provided,
            color: isDisabled ? 'var(--meteorite-grey)' : 'var(--space-grey)',
            top: '50%',
            whiteSpace: 'nowrap',
            fontSize: '0.875rem',
          }),
          valueContainer: (provided) => ({
            ...provided,
            padding: '0 6px',
            fontSize: '0.875rem',
          }),
          singleValue: (provided, { isDisabled }) => ({
            ...provided,
            color: isDisabled ? 'var(--meteorite-grey)' : 'var(--lunar-blue)',
          }),
          input: (provided) => ({
            ...provided,
            margin: 0,
          }),
          menu: (provided) => ({
            ...provided,
            margin: 0,
            border: '1px solid #7F8FB0',
            boxSizing: 'border-box',
            borderRadius: '3px',
            minHeight: '1rem',
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            color: 'var(--space-grey)',
            padding: 5,
          }),
          indicatorSeparator: () => ({}),
          option: (provided, { isSelected, isFocused }) => ({
            ...provided,
            display: 'flex',
            backgroundColor: isSelected
              ? 'var(--lunar-blue)'
              : isFocused && 'var(--lunar-dust)',
            fontWeight: isSelected && 600,
            ':active': {
              ...provided[':active'],
              backgroundColor: 'var(--sky-blue)',
            },
            minHeight: 'fit-content',
          }),
          noOptionsMessage: (provided) => ({
            ...provided,
            color: 'var(--lunar-blue)',
          }),
        }}
        defaultValue={props.defaultValue}
        value={
          props.value && { value: props.value.id, label: props.value.label }
        }
        placeholder={props.placeholder}
        onChange={(option) => {
          props.setFieldValue(props.name, option);
          if (props.saveState) {
            sessionStorage.setItem(props.name, JSON.stringify(option));
          }
        }}
        formatOptionLabel={({ value, label }) => (
          <div style={{ display: 'flex' }}>
            {props.value && props.value.id === value ? (
              <SelectedIcon
                style={{ alignSelf: 'center', marginRight: '0.3rem' }}
              />
            ) : null}
            <OptionLabel label={label} searchWords={searchWords} />
          </div>
        )}
        components={{ SingleValue }}
        options={transformItems(props.items, props.showId)}
        onInputChange={(val) => setQuery(val)}
        isDisabled={props.isDisabled}
      />
    </div>
  );
};

const OptionLabel = ({ label, searchWords }) => (
  <div style={{ display: 'flex', fontSize: 'var(--default-font-size)' }}>
    <div>
      <Highlighter
        highlightStyle={{ background: '#FAE55C' }}
        textToHighlight={label}
        searchWords={searchWords}
      />
    </div>
  </div>
);

const SingleValue = ({ data, ...props }) => (
  <components.SingleValue {...props}>{data.label}</components.SingleValue>
);

const transformItems = (items, showId) => {
  return (
    items &&
    items.map((item) => {
      return {
        value: item.id,
        label: showId ? `${item.id} - ${item.name}` : item.name,
      };
    })
  );
};

export default SearchSelect;
