import { LOCALES } from '../i18n/constants';
import React from 'react';

export const formatDateForDisplay = (utcTimestamp) => {
  if (!utcTimestamp) return null;
  const date = new Date(utcTimestamp);
  const month = date.toLocaleDateString(LOCALES.ENGLISH, { month: 'long' });
  const year = date.toLocaleDateString(LOCALES.ENGLISH, { year: 'numeric' });
  return { month, year };
};

export const cutOffDay = (date) => date.substring(0, 7);

export const DisplayDate = ({ date, className }) => {
  const display = formatDateForDisplay(date);
  if (!display) return null;
  return <div className={className}>{`${display.month}, ${display.year}`}</div>;
};
