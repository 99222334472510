import React from 'react';
import './TabList.css';

const TabList = ({ children }) => {
  return (
    <div className="tabList">
      <div className="tabs">{children}</div>
    </div>
  );
};

export default TabList;
