import clsx from 'clsx';
import React from 'react';
import './Tab.css';
import { useLocation, Link } from 'react-router-dom';
import { Routes } from '../../../containers/routes';

const Tab = ({ name, path }) => {
  const location = useLocation();
  if (location.pathname === Routes.HTTP_ERROR) return null;
  return (
    <Link to={path}>
      <div className={clsx('tab', path === location.pathname && 'selected')}>
        {name}
      </div>
    </Link>
  );
};

export default Tab;
