import React, { useState, useEffect, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import AddForm from '../../components/TimeReport/Form/addForm';
import Summary from '../../components/Summary';
import TimeReportService from '../../services/TimeReportService';
import { useHistory } from 'react-router-dom';

import './AddReportPageMobile.css';
import { Routes } from '../routes';
import useIsMobile from '../../hooks/useIsMobile';
import UserContext from '../../context/userContext';

const AddReportPageMobile = () => {
  const user = useContext(UserContext);

  const [timeReports, setTimeReports] = useState([]);
  const [workhours, setWorkhours] = useState(0);
  const [editable, setEditable] = useState(true);

  const history = useHistory();
  const isMobile = useIsMobile();

  useEffect(() => {
    fetchTimeReports();
  }, []);

  useEffect(() => {
    if (!isMobile) {
      history.push(Routes.NEW_REPORT);
    }
  }, [isMobile, history]);

  const fetchTimeReports = async () => {
    const { data } = await TimeReportService.get(user.officeLocation);
    setTimeReports(data.timereports);
    setEditable(!data.finished);
    setWorkhours(data.workhours);
  };

  const handleImport = async () => {
    const { data } = await TimeReportService.importPrevious();
    if (data.length > 0) {
      history.push(Routes.NEW_REPORT);
    }
  };

  const handleSubmit = async (values) => {
    await TimeReportService.create(values);
    history.push(Routes.NEW_REPORT);
  };

  return (
    <div className="addreport-mobile-wrapper">
      <div className="container">
        <div className="content-container">
          <div className="welcome-text">
            <FormattedMessage id="welcome-text" />
          </div>
          <AddForm editable={editable} handleSubmit={handleSubmit} />
          <div className="import-button">
            <p onClick={() => editable && handleImport()}>
              <FormattedMessage id="import-previous" />
            </p>
          </div>
          <div className="separator"></div>
          <Summary data={timeReports} maxHours={workhours} />
        </div>
      </div>
    </div>
  );
};

export default AddReportPageMobile;
