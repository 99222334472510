import BaseApiService from './BaseApiService';

const ENDPOINTS = {
  GET: (office) => `/timereport?office=${office ? office : ''}`,
  CREATE: '/timereport',
  PATCH: (id) => `/timereport/${id}`,
  DELETE: (id) => `/timereport/${id}`,
  HISTORY: (office) => `/timereport/history?office=${office ? office : ''}`,
  IMPORT: '/timereport/import',
};

class TimeReportService extends BaseApiService {
  async get(office) {
    return await this.apiClient.get(ENDPOINTS.GET(office));
  }

  async getHistory(office) {
    return await this.apiClient.get(ENDPOINTS.HISTORY(office));
  }

  async create(payload) {
    return await this.apiClient.post(ENDPOINTS.CREATE, payload);
  }

  async importPrevious() {
    return await this.apiClient.post(ENDPOINTS.IMPORT);
  }

  async update(id, payload) {
    return await this.apiClient.patch(ENDPOINTS.PATCH(id), payload);
  }

  async delete(id) {
    return await this.apiClient.delete(ENDPOINTS.DELETE(id));
  }
}

export default new TimeReportService();
