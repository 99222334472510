import React from 'react';
import { editReportSchema } from '../../../validation/FormValidation';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import { FormattedMessage } from 'react-intl';

import { Formik, Form, Field } from 'formik';

import './Form.css';

const EditForm = ({
  handleSave,
  handleDelete,
  project,
  discipline,
  allocation,
  comment,
}) => {
  return (
    <Formik
      initialValues={{
        allocation,
        comment
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={editReportSchema}
      onSubmit={handleSave}
    >
      {({ values, errors, setFieldValue }) => (
        <Form className="form">
          <Field
            as={Input}
            className="form-project"
            value={project ? project.name : null}
            disabled={true}
            label={<FormattedMessage id="thead-project" />}
          />
          <Field
            as={Input}
            className="form-discipline"
            value={discipline ? discipline.name : null}
            disabled={true}
            label={<FormattedMessage id="thead-discipline" />}
          />
          <div className="mobile-view-inputs">
            <Field
              type="number"
              max="999"
              as={Input}
              className="form-hours-mobile edit"
              value={values.allocation}
              setFieldValue={setFieldValue}
              error={errors.allocation}
              name="allocation"
              label={<FormattedMessage id="thead-hours" />}
            ></Field>
            <Field
              as={Input}
              name="comment"
              className="form-comment-mobile edit"
              value={values.comment}
              setFieldValue={setFieldValue}
              error={errors.allocation}
              label={<FormattedMessage id="thead-comment" />}
            ></Field>
          </div>
          <div className="edit-form-buttons">
            <Button
              style={{
                backgroundColor: 'white',
                color: 'var(--lunar-blue)',
                boxShadow: 'none',
                marginRight: '33px',
              }}
              type="button"
              onClick={handleDelete}
            >
              <FormattedMessage id="delete-button" />
            </Button>
            <Button type="submit">
              <FormattedMessage id="save-button" />
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditForm;
