import React from 'react';
import { FormattedMessage } from 'react-intl';

import './Summary.css';

const Summary = ({
  data = [],
  maxHours,
  summaryMessage = 'remaining-hours',
}) => {
  const sum = data.reduce(
    (total, item) => total + parseInt(item.allocation),
    0,
  );
  return (
    <div className="summary">
      <span className="sum">
        {sum}{' '}
        <span className="sum-remain">
          (
          <FormattedMessage
            id={summaryMessage}
            values={{ maxHours, remaining: maxHours - sum }}
          />
          ){'  '}
        </span>
      </span>
    </div>
  );
};

export default Summary;
