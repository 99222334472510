import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Button from '../Button';
import { ReactComponent as WarningIcon } from './Ide.svg';

import './WarningModal.css';
import { FormattedMessage } from 'react-intl';
import useKeyCapture from '../../hooks/useKeyCapture';

Modal.setAppElement('#root');
Modal.defaultStyles.overlay.backgroundColor = 'rgb(0,2,26, 0.6)';

const customStyles = {
  content: {
    padding: '0.75rem',
    paddingTop: '0.7rem',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    boxShadow:
      '0px 9px 12px rgba(32, 42, 62, 0.14), 0px 3px 16px rgba(32, 42, 62, 0.12), 0px 5px 6px rgba(32, 42, 62, 0.2)',
    borderRadius: '3px',
  },
};

const WarningModal = ({
  isOpen,
  onConfirm,
  onCancel,
  header,
  body,
  confirmText,
}) => {
  useKeyCapture('Enter', () => {
    isOpen && onConfirm();
  });
  useKeyCapture('Escape', () => {
    isOpen && onCancel();
  });

  return (
    <Modal isOpen={isOpen} style={customStyles}>
      <div className="modal-container">
        <div className="modal-header">{header}</div>
        <div className="modal-body">
          <div className="modal-icon">
            <WarningIcon />
          </div>
          {body}
        </div>
        <div className="modal-buttons">
          <Button
            style={{
              color: 'var(--lunar-blue)',
              background: 'white',
              boxShadow: 'none',
              marginRight: '3rem',
            }}
            className="modal-delete-button"
            onClick={onCancel}
          >
            <FormattedMessage id="cancel" />
          </Button>
          <Button onClick={onConfirm}>{confirmText}</Button>
        </div>
      </div>
    </Modal>
  );
};

export default WarningModal;
