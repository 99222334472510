import BaseApiService from './BaseApiService';

const ENDPOINTS = {
  LOGIN: '/auth/login',
  REFRESH: (token) => `/auth/refresh/${token}`,
  ME: '/auth/me',
};

class AuthService extends BaseApiService {
  async login() {
    return await this.apiClient.post(ENDPOINTS.LOGIN);
  }

  async refresh(token) {
    return await this.apiClient.get(ENDPOINTS.REFRESH(token));
  }

  async me() {
    return await this.apiClient.get(ENDPOINTS.ME);
  }
}

export default new AuthService();
