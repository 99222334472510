import React, { useState, useEffect, useContext } from 'react';
import Table from '../../components/Table';
import Summary from '../../components/Summary';
import TimeReportService from '../../services/TimeReportService';
import { DisplayDate } from '../../utils/DateUtil';
import { FormattedMessage } from 'react-intl';
import './ViewReports.css';
import UserContext from '../../context/userContext';
import { ThreeDots } from 'svg-loaders-react';
import { ReactComponent as ComputerEmptyIcon } from './Illustration_computer.svg';
import { ReactComponent as MobileEmptyIcon } from './illustration_mobile.svg';

const ViewReportsPage = () => {
  const user = useContext(UserContext);

  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const { data } = await TimeReportService.getHistory(user.officeLocation);
    setHistory(data);
    setLoading(false);
  };

  return (
    <div className="viewreports-wrapper">
      <div className="container">
        <div className="content-container">
          <div className="table-header">
            <Table
              editable={false}
              hideEmptyState={true}
              showHeader={
                history && Object.keys(history).length > 0 && !loading
              }
            />
          </div>
          {history && Object.keys(history).length > 0 ? (
            Object.keys(history)
              .sort()
              .reverse()
              .map(function (keyName) {
                return (
                  <div className="history-table" key={keyName}>
                    <DisplayDate className="table-date" date={keyName} />
                    <Table data={history[keyName].timereports} />
                    <Summary
                      data={history[keyName].timereports}
                      maxHours={history[keyName].workhours}
                      summaryMessage="remaining-hours-short"
                    />
                  </div>
                );
              })
          ) : !loading ? (
            <div className="empty-history">
              <div className="desktop-empty-icon">
                <ComputerEmptyIcon />
              </div>
              <div className="mobile-empty-icon">
                <MobileEmptyIcon />
              </div>
              <div className="empty-history-header">No reports available</div>
              <div className="empty-history-text">
                You haven’t added any reports yet, please add your reports in
                “New report”.
              </div>
            </div>
          ) : (
            <div className="view-loader">
              <ThreeDots width="60" fill="#273859" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewReportsPage;
