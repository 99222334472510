export const Routes = {
  NEW_REPORT: '/',
  VIEW_REPORTS: '/viewreports',
  ADD_REPORT_MOBILE: '/add',
  EDIT_REPORT_MOBILE: '/edit',
  HTTP_ERROR: '/error',
};

export const RouteNames = {
  '/': 'new-report',
  '/add': 'new-report',
  '/edit': 'new-report',
  '/viewreports': 'view-reports',
};
