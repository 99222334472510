import axios from 'axios';
import foreach from 'lodash/each';
import config from '../config';
import { redirectToLogin } from '../utils/Browser';
import Cookies from 'universal-cookie';

export class HttpService {
  constructor(clientConfig = {}) {
    this.client = axios.create(clientConfig);
    this.cookies = new Cookies();

    this.responseInterceptors = [];
    this.badResponseInterceptors = [];

    this.initializeResponseInterceptors();

    this.attachRequestInterceptor(this.userInfoIntercept);

    this.setUnauthorizedCallback(this.handleRefresh);

    this.attachBadResponseInterceptor(async (error) => {
      return await this.handleBadResponse({ error });
    });
  }

  async handleBadResponse({ error }) {
    try {
      const { status } = error.response;
      switch (status) {
        case 401:
          return await this.unauthorizedCallback(error.response.config);
        default:
          return Promise.reject(error);
      }
    } catch (e) {
      console.error(e);
    }
  }

  async handleRefresh({ method, url, data }) {
    const refresh_token = this.cookies.get('refresh_token');

    if (refresh_token) {
      const response = await this.client
        .get(`/auth/refresh`)
        .catch(redirectToLogin);

      if (response.status === 200) {
        return await this.client[method](url, data && JSON.parse(data));
      }
    } else {
      redirectToLogin();
    }
  }

  userInfoIntercept(request) {
    request.headers = {
      ...request.headers,
      'X-Timezone': new Date().getTimezoneOffset(),
    };
    return request;
  }

  setUnauthorizedCallback = (callback) => {
    this.unauthorizedCallback = callback;
  };

  attachHeaders = (headers = {}) => {
    Object.assign(this.client.defaults.headers, headers);
  };

  removeHeaders = (headers = []) => {
    headers.forEach((key) => delete this.client.defaults.headers[key]);
  };

  attachResponseInterceptor = (callback) => {
    this.responseInterceptors.push(callback);
  };

  attachRequestInterceptor(interceptor) {
    this.client.interceptors.request.use(interceptor);
  }

  attachBadResponseInterceptor = (callback) => {
    this.badResponseInterceptors.push(callback);
  };

  initializeResponseInterceptors = () => {
    this.client.interceptors.response.use(
      (response) => {
        foreach(this.responseInterceptors, (i) => {
          i(response);
        });

        return response;
      },
      async (error) => {
        if (error.response && error.response.status) {
          for (const badResponse of this.badResponseInterceptors) {
            const res = await badResponse(error);
            if (res) return res;
          }
        }
        return Promise.reject(error);
      },
    );
  };
}

const clientConfig = {
  baseURL: config.API_BASE_URL,
  withCredentials: true,
};

export default new HttpService(clientConfig);
