import React, { useContext, useState } from 'react';

import { ReactComponent as LogoIcon } from './Logo.svg';
import { ReactComponent as HamburgerMenu } from './Hamburger.svg';
import { ReactComponent as CrossIcon } from './Cross.svg';
import { ReactComponent as HeaderText } from './Header.svg';

import UserContext from '../../context/userContext';
import { useHistory, useLocation } from 'react-router-dom';
import { Routes, RouteNames } from '../../containers/routes';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';

import './Header.css';
import useIsMobile from '../../hooks/useIsMobile';

const handleOpenHelp = () =>
  window.open(
    'https://tobii.sharepoint.com/finance/SitePages/Project-Time-Reporting.aspx',
  );

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const user = useContext(UserContext);
  const isMobile = useIsMobile();
  const location = useLocation();

  if (location.pathname === Routes.HTTP_ERROR) return null;

  return (
    <div className="header-wrapper">
      <div className="header">
        <div
          onClick={() => setMenuOpen(!menuOpen)}
          className="mobile-hamburger-icon"
        >
          <HamburgerMenu />
        </div>
        <MobileHeader
          menuOpen={menuOpen && isMobile}
          setMenuOpen={setMenuOpen}
          user={user}
        />
        <div className="header-logo">
          <LogoIcon />
        </div>
        <div className="header-brand">
          <HeaderText />
        </div>
        {user && (
          <>
            <div className="header-user">{user.displayName}</div>
            <div className="header-options">
              <span className="header-option" onClick={handleOpenHelp}>
                <FormattedMessage id="help-button" />
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const MobileHeader = ({ menuOpen, setMenuOpen, user }) => {
  const history = useHistory();
  const location = useLocation();

  const linkToHome = () => {
    history.push(Routes.NEW_REPORT);
    setMenuOpen(false);
  };

  const linkToViewreports = () => {
    history.push(Routes.VIEW_REPORTS);
    setMenuOpen(false);
  };

  return (
    <>
      <div className={clsx('mobile-hamburger-container', menuOpen && 'open')}>
        <div className="top-content">
          <div className="mobile-hamburger-header">
            <div onClick={() => setMenuOpen(!menuOpen)}>
              <CrossIcon />
            </div>
            <div>
              <FormattedMessage id="menu" />
            </div>
          </div>
          <div
            onClick={linkToHome}
            className={clsx(
              'mobile-hamburger-item',
              location.pathname === Routes.NEW_REPORT && 'selected',
              location.pathname === Routes.ADD_REPORT_MOBILE && 'selected',
              location.pathname === Routes.EDIT_REPORT_MOBILE && 'selected',
            )}
          >
            <FormattedMessage id="new-report" />
          </div>
          <div
            onClick={linkToViewreports}
            className={clsx(
              'mobile-hamburger-item',
              location.pathname === Routes.VIEW_REPORTS && 'selected',
            )}
          >
            <FormattedMessage id="view-reports" />
          </div>
          <div onClick={handleOpenHelp} className="mobile-hamburger-item">
            <FormattedMessage id="help-button" />
          </div>
        </div>
        <div className="bottom-content">
          <div className="mobile-hamburger-user">
            {user && user.displayName}
          </div>
          <div className="mobile-brand-hamburger-header"></div>
        </div>
      </div>
      <div className="mobile-header">
        <FormattedMessage id={RouteNames[location.pathname]} />
      </div>
    </>
  );
};

export default Header;
