import React from 'react';
import './Loader.css';

const Loader = () => (
  <div className="loader la-ball-grid-pulse la-sunflare la-2x">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);

export default Loader;
