import React from 'react';
import { ReactComponent as DesktopIcon } from './DesktopIcon.svg';
import { ReactComponent as MobileIcon } from './MobileIcon.svg';
import { ReactComponent as LogoAndHeader } from './LogoAndHeader.svg';
import './HttpErrorPage.css';
import { FormattedMessage } from 'react-intl';

const HttpError = () => (
  <>
    <div className="errorpage-header">
      <div className="errorpage-logo">
        <LogoAndHeader />
      </div>
    </div>
    <div className="error-container">
      <div className="error-icon">
        <DesktopIcon />
      </div>
      <div className="error-icon-mobile">
        <MobileIcon />
      </div>
      <div className="error-header">
        <FormattedMessage id="auth-error-header" />
      </div>
      <div className="error-text">
        <FormattedMessage id="auth-error-first-paragraph" />
        <br></br>
        <br></br>{' '}
        <FormattedMessage
          values={{
            link: (
              <a target="_blank" href="https://tobii.freshservice.com/">
                Tobii’s IT Service Desk
              </a>
            ),
          }}
          id="auth-error-second-paragraph"
        />
      </div>
    </div>
  </>
);

export default HttpError;
