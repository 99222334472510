import React, { useEffect, useState } from 'react';
import EditForm from '../../components/TimeReport/Form/editForm';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { Routes } from '../routes';
import DeleteModal from '../../components/WarningModal';
import TimeReportService from '../../services/TimeReportService';
import useIsMobile from '../../hooks/useIsMobile';
import './EditReportPageMobile.css';

const EditReportPageMobile = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { state } = useLocation();
  const isMobile = useIsMobile();
  const history = useHistory();

  useEffect(() => {
    if (!state || !isMobile) {
      history.push(Routes.NEW_REPORT);
    }
  }, [state, history, isMobile]);

  const handleSave = async ({ allocation, comment }) => {
    await TimeReportService.update(state.id, { allocation, comment });
    history.push(Routes.NEW_REPORT);
  };

  const handleDelete = async () => {
    await TimeReportService.delete(state.id);
    history.push(Routes.NEW_REPORT);
  };

  return (
    <div className="editreport-mobile-wrapper">
      <div className="container">
        <div className="content-container">
          <div className="welcome-text">
            <FormattedMessage id="edit-text" />
          </div>
          <EditForm
            handleSave={handleSave}
            handleDelete={() => setModalOpen(true)}
            project={state ? state.project : null}
            discipline={state ? state.discipline : null}
            allocation={state ? state.allocation : null}
            comment={state ? state.comment : null}
          />
        </div>
      </div>
      <DeleteModal
        isOpen={modalOpen}
        header={<FormattedMessage id="delete-row" />}
        body={<FormattedMessage id="delete-text" />}
        confirmText={<FormattedMessage id="confirm-delete" />}
        onConfirm={handleDelete}
        onCancel={() => setModalOpen(false)}
      />
    </div>
  );
};

export default EditReportPageMobile;
