import BaseApiService from './BaseApiService';

const ENDPOINTS = {
  MARK: '/finishedMonth',
};

class FinishedMonthService extends BaseApiService {
  async setMarked(marked) {
    return marked
      ? await this.apiClient.post(ENDPOINTS.MARK)
      : await this.apiClient.delete(ENDPOINTS.MARK);
  }
}

export default new FinishedMonthService();
