import React, { useState, useEffect } from 'react';
import ProjectService from '../../../services/ProjectService';
import DisciplineService from '../../../services/DisciplineService';
import { addReportSchema } from '../../../validation/FormValidation';
import Input from '../../../components/Input';
import SelectWithSearch from '../../../components/Select';
import Button from '../../../components/Button';
import { injectIntl, FormattedMessage } from 'react-intl';

import { Formik, Form, Field } from 'formik';

import './Form.css';

const AddForm = ({ intl, editable, handleSubmit }) => {
  const [projects, setProjects] = useState([]);
  const [disciplines, setDisciplines] = useState([]);

  useEffect(() => {
    fetchProjects();
    fetchDisciplines();
  }, []);

  const sortById = (data) => {
    return data.sort((a, b) => a.id > b.id);
  };

  const fetchProjects = async () => {
    const { data } = await ProjectService.get();
    setProjects(sortById(data));
  };

  const fetchDisciplines = async () => {
    const { data } = await DisciplineService.get();
    setDisciplines(sortById(data));
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        project: JSON.parse(sessionStorage.getItem('project')) || null,
        discipline: JSON.parse(sessionStorage.getItem('discipline')) || null,
        allocation: sessionStorage.getItem('allocation') || '',
        comment: sessionStorage.getItem('comment') || '',
      }}
      validationSchema={addReportSchema}
      validateOnChange={false}
      onSubmit={(values, { resetForm }) => {
        handleSubmit({
          projectId: values.project.value,
          disciplineId: values.discipline.value,
          allocation: values.allocation,
          comment: values.comment,
        });
        sessionStorage.clear();
        resetForm({});
      }}
    >
      {({ setFieldValue, errors, values }) => (
        <Form className="form">
          <Field
            as={SelectWithSearch}
            placeholder={intl.formatMessage({
              id: 'select-project-placeholder',
            })}
            className="form-project"
            name="project"
            value={values.project}
            items={projects}
            showId
            setFieldValue={setFieldValue}
            error={errors.project}
            isDisabled={!editable}
            label={<FormattedMessage id="thead-project" />}
            saveState
          />
          <Field
            as={SelectWithSearch}
            placeholder={intl.formatMessage({
              id: 'select-discipline-placeholder',
            })}
            className="form-discipline"
            name="discipline"
            value={values.discipline}
            items={disciplines}
            showId
            setFieldValue={setFieldValue}
            error={errors.discipline}
            isDisabled={!editable}
            label={<FormattedMessage id="thead-discipline" />}
            saveState
          />
          <Field
            type="number"
            max="999"
            as={Input}
            placeholder="0"
            className="form-hours"
            name="allocation"
            value={sessionStorage.getItem('allocation')}
            setFieldValue={setFieldValue}
            error={errors.allocation}
            disabled={!editable}
            label={<FormattedMessage id="thead-hours" />}
            saveState
          ></Field>
          <Field
            as={Input}
            placeholder={intl.formatMessage({
              id: 'comment-placeholder',
            })}
            className="form-comment"
            name="comment"
            value={sessionStorage.getItem('comment')}
            setFieldValue={setFieldValue}
            error={errors.comment}
            disabled={!editable}
            label={<FormattedMessage id="thead-comment" />}
            saveState
          ></Field>
          <div className="mobile-view-inputs">
            <Field
              type="number"
              max="999"
              as={Input}
              placeholder="0"
              className="form-hours-mobile"
              name="allocation"
              value={sessionStorage.getItem('allocation')}
              setFieldValue={setFieldValue}
              error={errors.allocation}
              disabled={!editable}
              label={<FormattedMessage id="thead-hours" />}
              saveState
            ></Field>
            <Field
              as={Input}
              placeholder={intl.formatMessage({
                id: 'comment-placeholder',
              })}
              className="form-comment-mobile"
              name="comment"
              value={sessionStorage.getItem('comment')}
              setFieldValue={setFieldValue}
              error={errors.comment}
              disabled={!editable}
              label={<FormattedMessage id="thead-comment" />}
              saveState
            ></Field>
          </div>
          <Button disabled={!editable} className="add-button" type="submit">
            <FormattedMessage id="add-button" />
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default injectIntl(AddForm);
