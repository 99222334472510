import BaseApiService from './BaseApiService';

const ENDPOINTS = {
  GET: '/discipline',
};

class DisciplineService extends BaseApiService {
  async get() {
    return await this.apiClient.get(ENDPOINTS.GET);
  }
}

export default new DisciplineService();
