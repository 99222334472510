import clsx from 'clsx';
import React from 'react';
import './Button.css';

const Button = (props) => (
  <button
    {...props}
    className={clsx('button', props.disabled && 'disabled', props.className)}
  >
    {props.children}
  </button>
);

export default Button;
