import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Input.css';
import clsx from 'clsx';

const Input = (props) => {
  const [focused, setFocused] = useState(false);
  return (
    <div className={props.className}>
      <label className="input-label">{props.label}</label>
      <input
        {...props}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        placeholder={focused ? '' : props.placeholder}
        value={props.value ? props.value : ''}
        onChange={(event) => {
          const value = event.target.value;
          props.setFieldValue(props.name, value);
          if (props.saveState) {
            sessionStorage.setItem(props.name, value);
          }
        }}
        className={clsx(
          'form-input',
          props.disabled && 'disabled',
          props.error && 'error',
        )}
      />
    </div>
  );
};

Input.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
};

export default Input;
